import React, { useEffect, useMemo, useState } from 'react';
import {
	Alert,
	Box,
	Button, FormControlLabel,
	Grid,
	InputAdornment,
	Theme,
	Typography
} from '@mui/material';
import { ClipboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import { openForm } from '@/features/forms/slice';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import MuiTextField from '@/componentsMui/Shared/FormikComponents/MuiTextField';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import StyledDivider from '@/componentsMui/Shared/Widgets/StyledDivider';
import { muiShowSuccess, showException } from '@/features/swal/slice';
import {
	getCoins,
	transformTokensToAssets,
	validateWithdrawAddress
} from '@/features/coins/slice';
import { runOperation } from '@/features/operations/slice';
import useCoinWithdrawalFeeDebounce from '@/helpers/customHook/useCoinWithdrawalFeeDebounce';
import FormHeader from '../Deposit/Components/FormHeader';
import NumberFormat from 'react-number-format';
import bigDecimal from 'js-big-decimal';
import { AssetType, CoinType } from '@/features/coins/types';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import FormLabel from '@shared/Components/SendMoney/PaymentForm/FormLabel';
import AccountItem from '@/componentsMui/Banking/Account/AccountItem';
import { getAccountName } from '@helpers/getAccountName';
import { getCurrencySign } from '@features/fees/slice';
import { useTheme } from '@mui/styles';
import ButtonStyleTabs from '@shared/Widgets/Tab/ButtonStyleTabs';
import ButtonStyleTab from '@shared/Widgets/Tab/ButtonStyleTab';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import ConfirmationLine from '@shared/Components/SendMoney/PaymentForm/ConfirmationLine';
import StyledCheckbox from '@shared/Widgets/StyledCheckbox';
import { Contact, ContactPaymentTypes } from '@features/contacts/types';
import { RootState } from '@/rootReducer';
import ContactDropdown from '@shared/FormikComponents/ContactDropdown';
import useCurrencyLimits from '@/helpers/customHook/useCurrencyLimits';
import LoadingPageMui from '@/pages/LoadingPageMui';
import UserLimitMessage from '@/componentsMui/Shared/Components/UserLimitMessage';
import { NEW_LINE_REGEX } from '@/helpers/customHook/useValidations';
import { toNumber } from '@/helpers/round';
import useWithdrawalMinAmount from '@/helpers/customHook/useWithdrawalMinAmount';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';


enum WithdrawStep {
	AMOUNT = 'AMOUNT',
	BENEFICIARY = 'BENEFICIARY',
	CONFIRMATION = 'CONFIRMATION'
}

interface Props {
	cointList?: CoinType[],
	defaultCoin?: CoinType,
	onBack?: () => void,
	defaultContact?: Contact,
}

const Withdraw = ({ defaultCoin, onBack, defaultContact }: Props): React.ReactElement => {

	const closeModal = useCloseDialogNoEvent();
	const dispatch = useDispatch();
	const theme = useTheme() as Theme;
	const { fetchWithDelay } = useFetchWithDelay();
	const { t } = useTranslation('translations');
	const [step, setStep] = useState<WithdrawStep>(WithdrawStep.AMOUNT);
	const [payload, setPayload] = useState<{ fee: number, body: any, selectedAsset: AssetType }>(null);
	const defaultAsset = defaultCoin ? transformTokensToAssets([defaultCoin]) : undefined;
	const [selectedAsset] = React.useState<AssetType>(defaultAsset?.length > 0 ? defaultAsset[0] : undefined);
	const networkOptions = useMemo(() => {
		return selectedAsset?.networks?.map((item) => { return { key: item, value: item }; }) || [];
	}, [selectedAsset?.networks]);
	const [amount, setAmount] = useState<string>('');
	const [amountforFee, setAmountForFee] = useState<number>(0);

	const allContacts = useSelector((state: RootState) => state.contacts.contacts);
	const paymentContacts = useMemo(() => { return allContacts.filter(contact => contact.supportedPayments.includes(ContactPaymentTypes.CRYPTO) && (networkOptions.some(no => no.key === contact.cryptoNetwork) || contact.cryptoNetwork === null)); }, [networkOptions, allContacts]);
	const [saveToContacts, setSaveToContacts] = useState<boolean>(false);
	const [contact, setContact] = useState<Contact>(defaultContact ?? null);
	const [loading, setLoading] = useState<boolean>(false);

	const [network, setNetwork] = useState<string>(contact?.cryptoNetwork ?? selectedAsset?.networks[0] ?? '');

	const {
		userLimits,
		loadingLimits,
		isLimitSet,
		isLimitExceeded } = useCurrencyLimits(selectedAsset.symbol);


	const {
		minAmount,
		isMinAmountAvailable,
	} = useWithdrawalMinAmount(selectedAsset.symbol);

	const handleSelectFromContacts = (value: Contact, setFieldValue) => {
		const contact = paymentContacts.find(p => p.beneficiaryAccountId === value.beneficiaryAccountId);
		setContact(contact);
		setFieldValue('address', contact.account);
		setFieldValue('name', contact?.name ?? '');
		if (contact.cryptoNetwork) {
			handleNetworkChange(contact.cryptoNetwork);
		}
		// setNetwork(contact?.cryptoNetwork);
	};

	const handleAddressChange = (event: any, setFieldValue) => {
		const address = event.target.value;
		setContact(null);
		setFieldValue('address', address);
	};

	const { payInfo, initPayInfo, callGetPayInfo, setPayinfo, payInfoLoading } = useCoinWithdrawalFeeDebounce();

	const precision = defaultCoin?.account?.precision ?? 5;
	const amountInputPrecision = Math.min(precision, 5);


	const availableBalance = bigDecimal.compareTo(defaultCoin?.account?.availableBalance, 0) === 0 ? 0 : new bigDecimal(defaultCoin?.account?.availableBalance ?? 0).round(precision, bigDecimal.RoundingModes.DOWN).getValue();

	const isAvailableBalanceLessThanShow = bigDecimal.compareTo(defaultCoin?.account?.availableBalance, 0) && bigDecimal.compareTo(availableBalance, 0) === 0;


	const youpay = amountforFee; //new bigDecimal(Number(amountforFee) + Number(payInfo.calculatedFee)).round(precision, bigDecimal.RoundingModes.DOWN).getValue() ?? 0;
	const withdrawalAmount = new bigDecimal(Number(amountforFee) - Number(payInfo.calculatedFeeString)).getValue() ?? 0;

	const procIsDown = selectedAsset?.paymentProcess?.maintenance;
	const limitReached = isLimitExceeded(amount);


	const handleCloseModal = () => {
		setStep(WithdrawStep.AMOUNT);
		closeModal();
	};

	useEffect(() => {
		dispatch(openForm());
	}, [dispatch]);

	const amountInitialValues = {
		amount: amount || '',
		youpay: youpay || '',
	};

	const beneInitialValues = {
		address: defaultContact?.account ?? '',
		name: defaultContact?.name ?? '',
		reference: ''
	};

	const amountChange = (value, setFieldValue, setFieldTouched) => {
		const newAmount = toNumber(value);
		if (newAmount && newAmount > 0) {
			callGetPayInfo({ fromAmount: newAmount, fromAccountId: selectedAsset.accountId, proc: selectedAsset.paymentProc });
		} else {
			setPayinfo(initPayInfo);
		}
		setFieldValue('amount', newAmount);
		setAmount(`${newAmount}`);
		setAmountForFee(newAmount);
		setTimeout(() => setFieldTouched('youpay', true));
		setTimeout(() => setFieldTouched('amount', true));
	};



	const handlePaste = (event: ClipboardEvent<HTMLInputElement>, setFieldValue, setFieldTouched) => {
		const pastedText = event.clipboardData.getData('Text');
		if (pastedText.indexOf(',') !== -1) {
			event.preventDefault();
			amountChange(pastedText.replace(',', '.'), setFieldValue, setFieldTouched);
		}
	};


	const handleAmountChange = (event: React.ChangeEvent<any>, setFieldValue, setFieldTouched) => {
		amountChange(event.target.value, setFieldValue, setFieldTouched);
	};

	const setAmountToMax = async (setFieldValue, setFieldTouched) => {
		const availableBalance = Number(selectedAsset?.availableBalance);
		// const feeResult = await postCoinWithdrawalPayinfo({ fromAmount: availableBalance, fromAccountId: selectedAsset.accountId, proc: selectedAsset.paymentProc });
		// setPayinfo(feeResult);
		const amount = availableBalance < 0 ? 0 : availableBalance;
		amountChange(amount, setFieldValue, setFieldTouched);

		// setFieldValue('amount', amount);
		// setAmount(`${amount}`);
		// setAmountForFee(amount);
		// setTimeout(() => setFieldTouched('youpay', true));
		// setTimeout(() => setFieldTouched('amount', true));
	};

	const rawCurrentHoldings = selectedAsset?.availableBalance ?? '0';
	const withdrawalMinAmount = isMinAmountAvailable ? minAmount?.cryptoCcyString ?? '0' : '0';


	const amountValidationSchema = Yup.object({
		amount: Yup.number().typeError(t('form.validator.required')).label(t('tokens.header.withdrawalAmount'))
			.required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('tokens.header.maxTokens', { amount: rawCurrentHoldings }), (value) => bigDecimal.compareTo(rawCurrentHoldings, value) >= 0)
			.test('amount', t('tokens.validations.minWithdrawalAmount', { amount: withdrawalMinAmount }), (value) => bigDecimal.compareTo(value, withdrawalMinAmount) >= 0),
	});

	const beneValidationSchema = Yup.object({
		address: Yup.string().required(t('form.validator.required')),
		name: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().trim()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-./\s])+$/, t('form.validator.latinOnlyReference'))
			.min(6, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }))
			.max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
			.test('reference', t('form.validator.newLine'), (value) => value ? value.search(NEW_LINE_REGEX) === -1 : true)
			.test('reference', t('form.validator.minChars', { field: t('sendmoneyTranslation.data.reference'), chars: '6' }), (value) => value ? value.replace(/ /g, '').length >= 6 : true),
	});

	const amountSubmit = async () => {
		setStep(WithdrawStep.BENEFICIARY);
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		const { address, name, reference } = formData;

		try {
			await validateWithdrawAddress(address, network);
		} catch (err) {
			setFieldError('address', t('form.validator.invalidAddress'));
			return;
		}

		const body = {
			accountId: selectedAsset.accountId,
			amount: youpay,
			ccy: selectedAsset.symbol,
			beneficiaryCryptoAddress: address,
			beneficiaryCryptoNetwork: network,
			beneficiaryName: name,
			toBusiness: false,
			feeInAmount: true,
			details: reference
		};

		setPayload({ fee: payInfo.calculatedFee, body, selectedAsset });
		setSubmitting(false);
		setStep(WithdrawStep.CONFIRMATION);
	};


	const handleConfirm = async () => {
		try {
			setLoading(true);
			await runOperation(selectedAsset.paymentProc, {
				...payload.body,
				saveToContacts: saveToContacts
			});
			closeModal();
			/* if (walletType === WalletType.TOKEN) {
				dispatch(getTokens());
			} else {
				dispatch(getCoins());
			} */
			fetchWithDelay(getCoins(true), [3000, 5000, 10000]);
			muiShowSuccess('wallet.withdraw.withdrawConfirmation.registered', selectedAsset.symbol + ' ' + payload?.body.amount, t('wallet.withdraw.withdrawConfirmation.registeredText'));
		}
		catch (e) {
			closeModal();
			showException(e);
		} finally {
			setLoading(false);
		}

	};

	const handleNetworkChange = (
		newValue,
	) => {
		const value = newValue;
		if (value != null) {
			setNetwork(value);
		}
		setContact(null);
	};

	if (loadingLimits) return <LoadingPageMui />;

	return (
		<>
			{step === WithdrawStep.AMOUNT && <>
				<Grid container justifyContent='space-between'>
					<Grid item xs={12}>
						<FormHeader title={t('wallet.withdraw.withdraw')} showBack={!!onBack} mb='1rem' onBack={onBack} showMaintenace={procIsDown} />
					</Grid>
				</Grid>
				<Grid container flex={1} direction='column' >
					<Formik
						initialValues={amountInitialValues}
						validationSchema={amountValidationSchema}
						onSubmit={amountSubmit}
					>
						{({ errors, isSubmitting, setFieldValue, setFieldTouched }) => (
							<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Grid container spacing={1} >
									<Grid item xs={12}>
										<FormLabel>{t('form.fields.from')}</FormLabel>
										<AccountItem currency={defaultCoin.info.symbol} logoSource={defaultCoin.info.logo} name={getAccountName(t, defaultCoin.account)} balance={defaultCoin.account.availableBalance} decimalScale={isAvailableBalanceLessThanShow ? precision + 1 : precision} />
									</Grid>
									{selectedAsset && selectedAsset.symbol === 'UMW'
										? <Grid item xs={12} mt={3}>
											<Alert severity="warning">
												<MuiFormText>{t('wallet.withdraw.cannotWithdraw')}</MuiFormText >
											</Alert>
										</Grid>
										: <>
											<Grid item xs={12}>
												<Grid container item xs={12} my={1} justifyContent="space-between" alignItems="center">
													<Grid item>
														<FormLabel>{t('sendmoneyTranslation.data.amount')}</FormLabel>
													</Grid>
													{isLimitSet &&
														<Grid item xs={12}>
															<UserLimitMessage userLimits={userLimits} limitReached={limitReached} />
														</Grid>
													}
												</Grid>
												<NumberFormat
													name="amount"
													customInput={MuiTextField}
													className='large-number-input'
													onPaste={(event) => handlePaste(event, setFieldValue, setFieldTouched)}
													onChange={(event) => handleAmountChange(event, setFieldValue, setFieldTouched)}
													allowNegative={false}
													value={amount}
													displayType={'input'}
													placeholder={'0.00'}
													decimalScale={amountInputPrecision}
													allowedDecimalSeparators={['.']}
													fullWidth
													sx={{
														'& .MuiInputAdornment-root p': {
															fontSize: '1.3rem'
														},
														'& input::placeholder': {
															fontSize: '1.3rem',
														}
													}}
													inputProps={{ autoComplete: 'off', sx: { fontSize: '1.3rem' } }}
													InputProps={{
														style: { fontSize: '1.3rem' },
														sx: { background: theme.backgroundBoxLevel1, height: '74px!important', fontSize: '1.3rem' },
														startAdornment:
															<InputAdornment position="start">
																{selectedAsset.symbol}
															</InputAdornment>,
														endAdornment:
															<InputAdornment position='end'>
																<span style={{ height: '0.8rem', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.8rem', lineHeight: '0.8rem' }}
																	onClick={() => setAmountToMax(setFieldValue, setFieldTouched)}>MAX</span>
															</InputAdornment>,
													}}
												/>
											</Grid>
											<Grid item xs={12} mt='2rem'>
												<StyledDivider />
											</Grid>
											<Grid container item xs={12} justifyContent='space-between' alignItems='center'>
												<Grid item>
													<Typography color='text.primary' sx={{ fontSize: '1.225rem' }}>
														{t('sendmoney.data.youWillPay')}
													</Typography>
												</Grid>
												<Grid item>
													<Typography color='text.primary' fontWeight={700} sx={{ fontSize: '1.225rem' }}>
														<Typography variant='highlight'>
															{`${selectedAsset.symbol ?? getCurrencySign(selectedAsset.symbol)}`}
														</Typography>
														<NumberFormat
															id="youpay"
															name="youpay"
															displayType={'text'}
															decimalScale={precision}
															value={youpay}
															fixedDecimalScale={true}
															thousandsGroupStyle='thousand'
															thousandSeparator={true}
															prefix={' '}
															placeholder={'0.00'}
														/>
													</Typography>
												</Grid>
											</Grid>
											<Grid item xs={12} container justifyContent='right' sx={{ p: '0!important' }}>
												<Typography color='text.secondary' sx={{ fontSize: '0.7rem' }}>
													{t('sendmoney.data.includedWithFee')}
												</Typography>
												<Typography color='text.primary' sx={{ fontSize: '0.7rem', ml: 0.5 }} noWrap>
													<NumberFormat
														id="fee"
														name="fee"
														displayType={'text'}
														// decimalScale={precision}
														value={payInfo.calculatedFeeString ?? 0}
														fixedDecimalScale={false}
														thousandsGroupStyle='thousand'
														thousandSeparator={true}
														prefix={' '}
														placeholder={'0.00'}
													/>
												</Typography>
											</Grid>
										</>
									}
								</Grid>
								<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
									<Button
										key='buttonCancel'
										variant='customOutlined'
										onClick={handleCloseModal}
									>   	{t('form.buttons.close')}
									</Button>
									<Button
										key='prieview'
										type='submit'
										disabled={!isEmpty(errors) || isSubmitting || !selectedAsset?.paymentProc || payInfoLoading || (selectedAsset && selectedAsset.symbol === 'UMW') || procIsDown}
										variant='contained'
									>
										{t('form.buttons.continue')}
									</Button>
								</Grid>
							</Form>
						)}
					</Formik >
				</Grid>
			</>}
			{step === WithdrawStep.BENEFICIARY && <>
				<Grid container justifyContent='space-between'>
					<Grid item>
						<FormHeader title={t('wallet.withdraw.withdraw')} showBack={true} mb='1rem' onBack={() => setStep(WithdrawStep.AMOUNT)} />
					</Grid>
				</Grid>
				<Grid container flex={1} direction='column' >
					<Formik
						initialValues={beneInitialValues}
						validationSchema={beneValidationSchema}
						onSubmit={submit}
					>
						{({ errors, isSubmitting, setFieldValue }) => (
							<Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
								<Grid container spacing={1} >
									<Grid item xs={12} sm={6}>
										<ContactDropdown
											fieldName='contact'
											contactList={paymentContacts}
											selectedContact={contact}
											fieldlabel={t('sendmoneyTranslation.data.contact')}
											onContactChange={(value: Contact) => handleSelectFromContacts(value, setFieldValue)} />
									</Grid>
									<Grid item xs={12}>
										<StyledDivider />
									</Grid>
									<Grid item xs={12}>
										<FormLabel>{t('tokens.header.network')}</FormLabel>
									</Grid>
									<Grid item xs={12} justifyContent={'start'}>
										<ButtonStyleTabs
											value={network}
											onChange={(event, newValue) => handleNetworkChange(newValue)}
										>{networkOptions.map((item) => {
											return (
												<ButtonStyleTab key={item.key} value={item.value} label={item.value} />
											);
										})}
										</ButtonStyleTabs>
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											name="address"
											label={t('tokens.header.withdrawalAddress')}
											onChange={(event) => handleAddressChange(event, setFieldValue)}
											fullWidth />
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											name="name"
											label={t('tokens.withdrawal.beneName')}
											fullWidth />
									</Grid>
									<Grid item xs={12}>
										<MuiTextField
											name="reference"
											multiline
											fullWidth
											inputProps={{ autoComplete: 'off', maxLength: 140 }}
											label={t('sendmoneyTranslation.data.reference')}
											rows={2} />
									</Grid>
								</Grid>
								<Grid container flex={1} alignItems='flex-end' justifyContent='space-between' pt={'3rem'}>
									<Button
										key='buttonCancel'
										variant='customOutlined'
										onClick={() => setStep(WithdrawStep.AMOUNT)}
									>   	{t('form.buttons.back')}
									</Button>
									<Button
										key='prieview'
										type='submit'
										disabled={!isEmpty(errors) || isSubmitting || !selectedAsset?.paymentProc || payInfoLoading || (selectedAsset && selectedAsset.symbol === 'UMW')}
										variant='contained'> 	{t('form.buttons.continue')}
									</Button>
								</Grid>
							</Form>
						)}
					</Formik >
				</Grid>
			</>}
			{step === WithdrawStep.CONFIRMATION && <>
				<Grid container >
					<Grid item xs={12} >
						<FormHeader title={t('sendmoney.data.confirmheader')} onBack={() => setStep(WithdrawStep.BENEFICIARY)} />
					</Grid>
					<Grid container item xs={12} alignItems="center" flexWrap='nowrap'>
						<TokenAvatar symbol={payload.selectedAsset.symbol} logoSource={payload.selectedAsset.logo} variant={TokenAvatarSizeEnum.normal} />
						<Typography ml={2} color='text.secondary' sx={{ fontSize: '1.225rem' }}>
							{payload.selectedAsset.symbol} - {t('wallet.withdraw.withdraw')}
						</Typography>
					</Grid>
					<Grid container item xs={12} mt={1}>
						<Box sx={(theme) => ({
							background: theme.backgroundBoxLevel1,
							borderRadius: theme.mainBorderRadius,
							p: 2,
							width: '100%',
							mb: { xs: 3, md: 0 },
							mt: 1
						})}>
							<Grid container item rowSpacing={1}>
								<Grid item xs={12}>
									<ConfirmationLine label={t('tokens.withdrawal.from')}>
										{`${payload.selectedAsset.name} (${payload.selectedAsset.symbol})`}
									</ConfirmationLine>
								</Grid>
								<Grid item xs={12}>
									<ConfirmationLine label={t('tokens.withdrawal.to')}>
										{payload?.body.beneficiaryCryptoAddress}
									</ConfirmationLine>
								</Grid>
								<Grid item xs={12}>
									<ConfirmationLine label={t('tokens.withdrawal.withdrawalAmount')}>
										<NumberFormat
											displayType={'text'}
											decimalScale={precision}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={withdrawalAmount}
											prefix={`${payload.selectedAsset.symbol} `} />
									</ConfirmationLine>
								</Grid>
								<Grid item xs={12}>
									<ConfirmationLine label={t('tokens.withdrawal.estimatedFee')}>
										<NumberFormat
											displayType={'text'}
											// decimalScale={precision}
											fixedDecimalScale={false}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={payInfo.calculatedFeeString}
											prefix={`${payload.selectedAsset.symbol} `} />
									</ConfirmationLine>
								</Grid>
								<Grid item xs={12}>
									<ConfirmationLine label={t('sendmoney.data.totalAmount')}>
										<Typography
											fontSize='1.225rem'
											fontWeight='800'
											lineHeight='1.288rem'
											variant='highlight'
											style={{ display: 'inline' }}
										>
											<NumberFormat
												displayType={'text'}
												// decimalScale={precision}
												fixedDecimalScale={false}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												value={youpay}
												prefix={`${payload.selectedAsset.symbol} `} />
										</Typography>
									</ConfirmationLine>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					{isEmpty(contact) &&
						<Grid item xs={12} mt={2}>
							<FormControlLabel
								control={<StyledCheckbox
									checked={saveToContacts}
									onChange={() => { setSaveToContacts(!saveToContacts); }}
									disabled={!isEmpty(contact)} />}
								label={<MuiFormText>{t('sendmoneyTranslation.data.addtocontacts')}</MuiFormText>} />
						</Grid>
					}
				</Grid>
				<Grid container mt='auto' pt='2rem' columnSpacing={2}>
					<Grid item xs={6}>
						<Button
							variant='customOutlined'
							color='secondary'
							onClick={() => setStep(WithdrawStep.BENEFICIARY)}
							sx={{ width: { xs: '100%', md: 'auto' } }}
						>  {t('form.buttons.back')}
						</Button>
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end'>
						<Button
							disabled={loading}
							variant="contained"
							color="primary"
							sx={{ width: { xs: '100%', md: 'auto' } }}
							onClick={handleConfirm}
						>
							{t('form.buttons.confirm')}
						</Button>
					</Grid>
				</Grid>
			</>}
		</>
	);
};

export default Withdraw;
