
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@/features/account/types';
import './TrustCard.css';
import { Grid, Typography } from '@mui/material';
import MuiHeader from '@/componentsMui/Shared/Widgets/MuiText/MuiHeader';
import DiplayBigNumber from '@/componentsMui/Shared/Components/DiplayBigNumber';
import { getCurrencySign } from '@/features/fees/slice';
import { hiddenBabalance } from '@/helpers/placeholders';

interface Props {
	account: BankAccount,
	hideValues: boolean
}

const TrustCardContent = ({ account, hideValues }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');

	return (
		<Grid container rowGap={1}>
			<Grid item xs={12} container justifyContent='space-between'>
				<MuiHeader> {account.currency} {t('trusts.accountName')}</MuiHeader>
				{/* <ClickableTooltip title={t('trusts.text')}>
					<InfoOutlinedIcon sx={theme => ({ fontSize: '1.5rem', fill: theme.palette.text.primary })} />
				</ClickableTooltip> */}
			</Grid>
			<Grid item xs={12}>
				{hideValues ? hiddenBabalance :
					<DiplayBigNumber
						prefix={<Typography variant='highlight' fontSize='1.625rem'>
							{`${account.currencySymbol ?? getCurrencySign(account.currency)}`}
						</Typography>}
						amount={account.availableBalance}
					/>}
			</Grid>
			{/* <Grid item xs={12}>
				<MuiTextTrxSmall sx={{ opacity: 0.7 }}>{t('trusts.text')}</MuiTextTrxSmall>
			</Grid> */}
		</Grid>
	);
};

export default TrustCardContent;

