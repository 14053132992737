import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import status from '@features/status/statusSlice';
import user from '@features/user/userSlice';
import kyc from '@features/kyc/slice';
import accounts from '@features/account/slice';
import cards from '@features/card/slice';
import swal from '@features/swal/slice';
import twoFA from '@features/2fa/slice';
import credentials from '@features/user/credentialsSice';
import transaction from '@features/transaction/transactionSlice';
import tabs from '@features/tabs/slice';
import operations from '@features/operations/slice';
import faq from '@features/faq/slice';
import tickets from '@features/tickets/slice';
import contacts from '@features/contacts/slice';
import stocks from '@features/stocks/slice';
import employee from '@features/employee/slice';
import trust from '@features/trust/slice';
import crypto from '@features/crypto/slice';
import settings from '@features/settings/slice';
import allowedCountries from '@features/countries/slice';
import forms from '@features/forms/slice';
import drawer from '@features/drawer/slice';
import tokens from '@features/tokens/slice';
import vaults from '@/features/earn/slice';
import kyb from '@features/kyb/slice';
import coins from '@features/coins/slice';
import transactionsLazyLoad from '@features/transactionsLazyLoad/slice';
import business from '@features/business/slice';
import walletHistory from '@features/walletHistory/slice';
import earnings from '@features/earn/earningsSlice';
import allocations from '@features/earn/allocationsSlice';
import allVaultDeposits from '@features/earn/depositsSlice';
import landingStep from '@features/landingStep/slice';
import userNeuralid from '@/features/userNeuralid/sliceUserNeuralid';
import statistics from '@/features/statistics/statisticsSlice';
import maintenance from '@/features/maintenance/maintenanceSlice';
import disclaimers from '@/features/disclaimer/disclaimerSlice';
import payouts from '@features/earn/payoutSlice';
import userLimits from '@/features/limits/slice';
import supportChat from '@features/supportChat/slice';
import anonSupportChat from '@features/anonSupportChat/slice';
import italyTerritory from '@features/italyTerritory/italyTerritorySlice';
import bulkPayments from '@features/bulkpayments/bulkPaymentsSlice';
import invoices from '@features/invoices/invoicesSlice';
import countryState from '@features/countryStates/countryStateSlice';

const appReducer = combineReducers({
	forms, drawer, contacts, user,
	accounts, cards, status,
	swal, credentials, transaction, kyc, twoFA,
	tabs, operations,
	faq, tickets,
	stocks, employee,
	trust, crypto,
	settings, allowedCountries,
	tokens, coins, vaults, kyb,
	transactionsLazyLoad, business,
	walletHistory, earnings, allocations, landingStep,
	allVaultDeposits, userNeuralid, statistics,
	maintenance, disclaimers, payouts, supportChat, userLimits, anonSupportChat,
	italyTerritory, bulkPayments, invoices, countryState
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'RESET_APP') {
		const { status, settings, allowedCountries } = state;
		state = {} as RootState;
		state = {
			...state,
			settings,
			status,
			allowedCountries
		};
	}
	if (action.type === 'SWITCH_REPRESENTATION') {
		state = {
			...state,
			accounts: undefined,
			cards: undefined,
			tokens: undefined,
			tabs: undefined,
			vaults: undefined,
			coins: undefined,
			statistics: undefined,
		};
	}
	return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>

export default rootReducer;
