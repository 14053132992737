import * as React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { runOperation } from '@/features/operations/slice';
import { showException, showSuccess } from '@/features/swal/slice';
import FormContentBox from '@/componentsMui/Shared/Widgets/Box/FormContentBox';
import { getAccountName } from '@/helpers/getAccountName';
import { getCurrencySign } from '@/features/fees/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationRow';
import MuiFormField from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { SendMoneyPayloadType } from './SendMoneyDetails';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';
import { getAccounts } from '@/features/account/slice';

export interface Props {
	payload: SendMoneyPayloadType,
	prevStep: () => void,
	onComplete: () => void
}

const SendMoneyConfirmation = ({ payload, prevStep, onComplete }: Props) => {

	const { t } = useTranslation('translations');
	const { fetchWithDelay } = useFetchWithDelay();
	const curr = getCurrencySign(payload.account.currency);

	const send = async () => {
		try {
			await runOperation(payload.processProcedure, {
				accountFromId: payload.account.accountId,
				accountToId: payload.accountToId,
				amount: payload.amount,
				details: payload.reference,
				ccy: payload.account.currency
			});

			await showSuccess({ title: t('swal.MONEY_SENT'), confirmBTNText: t('form.buttons.close') });
			fetchWithDelay(getAccounts(), [3000, 5000, 10000]);
		} catch (e) {
			await showException(e);
		}
		finally { onComplete(); }
	};

	const handleConfirm = () => {
		send();
	};

	return (
		<div className='form-content__wrap__bb'>
			<FormContentBox noBackground={true} className='form-content_main-area wp' >
				<Grid container spacing={1} >
					<Grid item xs={12}>
						<FormHeader title={t('sendmoney.data.confirmheader')} showBack={true} onBack={prevStep} />
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('form.fields.from')}>
							<MuiFormField>  {getAccountName(t, payload.account)}</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.transferamount')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={payload.amount}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.transferfee')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={payload.fee}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					<Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.youpay')}>
							<MuiFormField>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={payload.youpay}
									prefix={`${curr} `} />
							</MuiFormField>
						</ConfirmationBox>
					</Grid>
					{payload?.firstName &&
						<Grid item xs={12}>
							<ConfirmationBox label={t('sendmoney.data.beneficiary')}>
								<MuiFormField>{payload.firstName} {payload.lastName}</MuiFormField>
							</ConfirmationBox>
						</Grid>
					}
					{payload?.reference && <Grid item xs={12}>
						<ConfirmationBox label={t('sendmoney.data.reference')}>
							<MuiFormField>  {payload?.reference}</MuiFormField>
						</ConfirmationBox>
					</Grid>
					}
				</Grid>
				<Grid container mt='auto' pt='2rem' columnSpacing={2}>
					<Grid item xs={6}>
						<Button
							variant='customOutlined'
							color='secondary'
							onClick={prevStep}
							sx={{width: {xs: '100%', md: 'auto'}}}
						>  {t('form.buttons.back')}
						</Button>
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end'>
						<Button
							variant="contained"
							color="primary"
							sx={{width: {xs: '100%', md: 'auto'}}}
							onClick={handleConfirm}
						>
							{t('createaccounttab.btn')}
						</Button>
					</Grid>
				</Grid>
			</FormContentBox>
		</div>);
};

export default SendMoneyConfirmation;
