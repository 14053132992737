import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Transaction } from '../TransactionStatements.spec';
import DetailLine from '@shared/Widgets/DetailLine';


interface Props {
	details: Transaction,
}

const TransferIncoming = ({ details }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid container>
			<DetailLine
				label={t('transactiondetails.main.status')}
				value={t('status.' + details.status)}
			/>
			{(details?.remitterAccount || details?.remitterAccountNumber) &&
				<DetailLine
					label={t('transactiondetails.remitter.fromAccount')}
					value={`${[details?.remitterAccount ?? details?.remitterAccountNumber, details?.remitterSortCode].filter(Boolean).join(' ')}`}
				/>
			}
			{details?.remitterName &&
				<DetailLine
					label={t('transactiondetails.remitter.name')}
					value={`${details.remitterName}`}
				/>
			}
			{
				details?.cardNumberMask ? (
					<DetailLine
						label={t('transactiondetails.beneficiary.toCard')}
						value={`${details?.cardNumberMask}`}
					/>
				) : (
						(details?.beneficiaryAccount || details?.beneficiaryAccountNumber) &&
						<DetailLine
							label={t('transactiondetails.beneficiary.toAccount')}
							value={`${[details?.beneficiaryAccount ?? details?.beneficiaryAccountNumber, details?.beneficiarySortCode].filter(Boolean).join(' ')}`}
						/>
					)
			}
			{details.details &&
				<DetailLine
					label={t('transactiondetails.details.reference')}
					value={`${details.details}`}
				/>
			}
		</Grid>
	);
};

export default TransferIncoming;
