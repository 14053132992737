import * as React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { muiShowSuccess, showException } from '@features/swal/slice';
import { runOperation } from '@features/operations/slice';
import { useCloseDialogNoEvent } from '@helpers/customHook/useCloseDialog';
import { getAccountName } from '@helpers/getAccountName';
import { getCurrencySign } from '@features/fees/slice';
import { AccountTransferPayloadType } from '@/componentsMui/Type/BetweenAccountsPayloadType';
import ConfirmationLine from '@shared/Components/SendMoney/PaymentForm/ConfirmationLine';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import NumberFormat from 'react-number-format';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import { getAccounts } from '@/features/account/slice';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';

export interface Props {
	accountTransferPayload: AccountTransferPayloadType,
	noBackground?: boolean,
	prevStep: () => void,
	onBack?: () => void,
	title?: string,
}

const BetweenAccountsConfirmation = ({ accountTransferPayload, prevStep, onBack, title }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const { fetchWithDelay } = useFetchWithDelay();
	const [submitting, setSubmitting] = React.useState(false);
	
	const send = async () => {
		setSubmitting(true);
		try {
			await runOperation(accountTransferPayload.processProcedure, {
				accountFromId: accountTransferPayload.fromAccount.accountId,
				accountToId: accountTransferPayload.toAccount.accountId,
				amount: accountTransferPayload.amount,
				ccy: accountTransferPayload.fromAccount.currency
			});

			fetchWithDelay(getAccounts(), [3000, 5000, 10000]);
			await muiShowSuccess('paymentsubmitted.data.headerTransaction',
				getCurrencySign(accountTransferPayload.fromAccount?.currency) + ' ' + accountTransferPayload?.amount,
				t('paymentsubmitted.data.toaccount', { account: getAccountName(t, accountTransferPayload.toAccount) }),
				t('paymentsubmitted.data.yousent'));

		} catch (e) {
			await showException(e);
		}
		finally { closeModal();setSubmitting(false); }
	};

	const handleConfirm = async () => {
		await send();
	};

	const curr = getCurrencySign(accountTransferPayload.fromAccount.currency ?? '');

	return (
		<div className='form-content__wrap__bb'>
			<Grid container >
				<Grid item xs={12} >
					<FormHeader title={t('sendmoney.data.confirmheader')} onBack={onBack} showBack={!!onBack} />
				</Grid>
				<Grid container item xs={12} alignItems="center" flexWrap='nowrap'>
					<TokenAvatar symbol={accountTransferPayload.fromAccount.currency} logoSource={accountTransferPayload.fromAccount.currencyLogo ? accountTransferPayload.fromAccount.currencyLogo : 'https://abcmob-public.s3.eu-central-1.amazonaws.com/currency_logo/GBP.png'} variant={TokenAvatarSizeEnum.normal} />
					<Typography ml={2} color='text.secondary' sx={{fontSize: '1.225rem'}}>
						{accountTransferPayload.fromAccount.currency} - {title ?? t('banking.addMoney.transfer')}
					</Typography>
				</Grid>
				<Grid container item xs={12} mt={1}>
					<Box sx={(theme) => ({
						background: theme.backgroundBoxLevel1,
						borderRadius: theme.mainBorderRadius,
						p: 2,
						width: '100%',
						mb: { xs: 3, md: 0 },
						mt: 1
					})}>
						<Grid container item rowSpacing={1}>
							<Grid item xs={12}>
								<ConfirmationLine label={t('form.fields.from')}>
									{`${getAccountName(t, accountTransferPayload.fromAccount)} `}
									<Typography
										fontSize='0.813rem'
										fontWeight='400'
										lineHeight='1.188rem'
										color={'text.secondary'}
										style={{display: 'inline'}}
									>
										(<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={accountTransferPayload.fromAccount.availableBalance}
										prefix={`${getCurrencySign(accountTransferPayload.fromAccount.currency)} `} />)
									</Typography >
								</ConfirmationLine>
							</Grid>
							<Grid item xs={12}>
								<ConfirmationLine label={t('form.fields.to')}>
									{`${getAccountName(t, accountTransferPayload.toAccount)} `}
									<Typography
										fontSize='0.813rem'
										fontWeight='400'
										lineHeight='1.188rem'
										color={'text.secondary'}
										style={{display: 'inline'}}
									>
										(<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={accountTransferPayload.toAccount.availableBalance}
										prefix={`${getCurrencySign(accountTransferPayload.toAccount.currency)} `} />)
									</Typography >
								</ConfirmationLine>
							</Grid>
							<Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.transferamount')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={accountTransferPayload.amount}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid>
							<Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.transferfee')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={accountTransferPayload.fee}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid>
							<Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.totalAmount')}>
									<Typography
										fontSize='1.225rem'
										fontWeight='800'
										lineHeight='1.288rem'
										variant='highlight'
										style={{display: 'inline'}}
									>
										<NumberFormat
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={accountTransferPayload.youpay}
											prefix={`${curr} `} />
									</Typography>
								</ConfirmationLine>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
			<Grid container mt='auto' pt='2rem' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={prevStep}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button
						variant="contained"
						color="primary"
						sx={{ width: { xs: '100%', md: 'auto' } }}
						onClick={handleConfirm}
						disabled={submitting}
					>
						{t('createaccounttab.btn')}
					</Button>
				</Grid>
			</Grid>
		</div>);
};

export default BetweenAccountsConfirmation;
