import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { showException, showSuccess } from '@/features/swal/slice';
import { runOperation } from '@/features/operations/slice';
import { CardTopupPayloadType } from './TopUpDetails';
import { useCloseDialogNoEvent } from '@/helpers/customHook/useCloseDialog';
import MuiTextConfirmationLabel from '@/componentsMui/Shared/Widgets/MuiText/MuiTextConfirmationLabel';
import MuiTextConfirmation from '@/componentsMui/Shared/Widgets/MuiText/MuiFormField';
import { getCurrencySign } from '@/features/fees/slice';
import NumberFormat from 'react-number-format';
import ConfirmationBox from '@/componentsMui/Shared/Components/ConfirmationBox';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { CardIntegrationEnum } from '@/features/card/types';
import { getCards } from '@/features/card/slice';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';

interface Props {
	payload: CardTopupPayloadType,
	prevStep: () => void
}

const TopUpConfirmation = ({ payload, prevStep }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const { fetchWithDelay } = useFetchWithDelay();
	const isSHCard = payload.toCard?.process?.integration === CardIntegrationEnum.SH_FINANCE;

	const send = async () => {

		try {
			const opearationPayload = {
				amount: payload.amount,
				accountFromId: payload.fromAccount.accountId,
				accountToId: payload.toCard.accountId,
				ccy: payload.toCard.currency
			};
			await runOperation(payload.operation, opearationPayload);
			fetchWithDelay(getCards(), [3000, 5000, 10000]);
			await showSuccess({
				title: 'paymentsubmitted.data.header_topup', text: t('paymentsubmitted.data.topupamount', {
					amount: (payload?.toCard?.currencySymbol + '' + payload?.amount.toFixed(2)),
				}), mui: true
			});

			closeModal();

		} catch (e) {
			await showException(e);
			closeModal();
		}
	};

	const handleConfirm = () => {
		send();
	};


	const mainContent =
		<Grid container
			spacing={3}>
			<Grid item xs={12}>
				<FormHeader title={t('sendmoney.data.confirmheader')} showBack={!!prevStep} onBack={prevStep} />
			</Grid>

			<Grid item xs={12}>
				<ConfirmationBox>
					<Grid container className='confirmationInfo' sx={{ px: { xs: 1, sm: 3 } }} rowSpacing={1}>
						<Grid item container xs={12}>
							<Grid item xs={4} sx={{ pr: { xs: 0, sm: 4 } }} display='flex' alignItems='center'>
								<MuiTextConfirmationLabel variant='body2'> {t('form.fields.from')}</MuiTextConfirmationLabel>
							</Grid>
							<Grid item container xs={8} display='flex' alignItems='center'>
								<Grid item xs={12}> <MuiTextConfirmation> {payload.fromAccount.name}</MuiTextConfirmation></Grid>
								<Grid item xs={12}>
									<Typography variant='gradient' fontWeight='400' fontSize='0.813rem' lineHeight='19px'>
										<NumberFormat
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${getCurrencySign(payload.fromAccount.currency)} `}
											value={payload.fromAccount.availableBalance} />
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={4} sx={{ pr: { xs: 0, sm: 4 } }} display='flex' alignItems='center'>
								<MuiTextConfirmationLabel variant='body2'> {t('form.fields.to')}</MuiTextConfirmationLabel>
							</Grid>
							<Grid item container xs={8} display='flex' alignItems='center'>
								<Grid item xs={12}> <MuiTextConfirmation> {isSHCard ? t('cards.list.visaCard') : payload.toCard.name}</MuiTextConfirmation></Grid>
								<Grid item xs={12}>
									<Typography variant='gradient' fontWeight='400' fontSize='0.813rem' lineHeight='19px'>
										<NumberFormat
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${getCurrencySign(payload.toCard.currency)} `}
											value={payload.toCard.availableBalance} />
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</ConfirmationBox>
			</Grid>

			<Grid item xs={12}>
				<ConfirmationBox>
					<Grid container className='confirmationInfo' sx={{ px: { xs: 1, sm: 3 } }} rowSpacing={1}>
						<Grid item container xs={12}>
							<Grid item xs={4} sx={{ pr: { xs: 0, sm: 4 } }} display='flex' alignItems='center'>
								<MuiTextConfirmationLabel variant='body2'> {t('cards.topUpCard.amount')}</MuiTextConfirmationLabel>
							</Grid>
							<Grid item xs={8} display='flex' alignItems='center'>
								<MuiTextConfirmation>
									<NumberFormat
										value={payload.amount}
										prefix={`${getCurrencySign(payload.toCard.currency)} `}
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
									/>
								</MuiTextConfirmation>
							</Grid>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={4} sx={{ pr: { xs: 0, sm: 4 } }} display='flex' alignItems='center'>
								<MuiTextConfirmationLabel variant='body2'> {t('sendmoney.data.transferfee')}</MuiTextConfirmationLabel>
							</Grid>
							<Grid item xs={8} display='flex' alignItems='center'>
								<MuiTextConfirmation>
									<NumberFormat
										value={payload.fee}
										prefix={`${getCurrencySign(payload.toCard.currency)} `}
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
									/>
								</MuiTextConfirmation>


							</Grid>
						</Grid>


						<Grid item container xs={12}>
							<Grid item xs={4} pr={4} display='flex' alignItems='center'>
								<MuiTextConfirmationLabel variant='body2'> {t('sendmoney.data.youpay')}</MuiTextConfirmationLabel>
							</Grid>
							<Grid item xs={8} display='flex' alignItems='center'>
								<MuiTextConfirmation>
									<NumberFormat
										value={payload.amount + payload.fee}
										prefix={`${getCurrencySign(payload.toCard.currency)} `}
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
									/>
								</MuiTextConfirmation>
							</Grid>
						</Grid>
					</Grid>
				</ConfirmationBox>
			</Grid>
		</Grid>;


	const buttonContent = [
		<Button
			key="buttonCancel"
			className='customDialogButton'
			variant='customOutlined'
			onClick={closeModal}
		>   {t('form.buttons.close')}
		</Button>,
		<Button
			key="buttonNext"
			className='customDialogButton'
			variant="contained"
			color="primary"
			disabled={payload.operation === null || payload.operation === undefined}
			onClick={handleConfirm}
		>
			{t('createaccounttab.btn')}
		</Button>];

	return (
		<>
			<Grid container>
				{mainContent}
			</Grid>
			<Grid container className='form-content_botton-area' justifyContent='space-between'>
				{buttonContent}
			</Grid>
		</>
	);
};

export default TopUpConfirmation;

