import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';
import { getComuneList } from '@/features/italyTerritory/italyTerritorySlice';
import { RootState } from '@/rootReducer';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
    countryIso3: string,
    value: string,
    labelComune?: string,
    labelCity?: string,
    onChange: (value: string) => void,
}

const ComuneSelect = ({ countryIso3, value, labelComune, labelCity, onChange }: Props) => {

    const { comunes } = useSelector((state: RootState) => state.italyTerritory);
    const dispatch = useDispatch();


    const comuneList = comunes.map(p => ({ code: p.codiceCatastale, label: `${p.nameIt}  (${p.regionIt})` }));

    useEffect(() => {
        if (comunes.length === 0) {
            dispatch(getComuneList());
        }
    }, [comunes.length, dispatch]);

    const handleCountryChange = (event: any, newValue: any) => {
        onChange(newValue.code);
    };
    return (
        countryIso3 === 'ITA' ?
            <Autocomplete
                options={comuneList}
                value={comuneList.find(p => p.code === value) || { code: '', label: '' }}
                onChange={handleCountryChange}
                disableClearable
                PaperComponent={({ children }) => (
                    <StyledPaper>{children}</StyledPaper>
                )}
                isOptionEqualToValue={(option, value) => value === undefined || option.code === value.code || value?.code === ''}
                renderInput={(params) => <TextField
                    name="shareHolderDocumentType" {...params}
                    label={labelComune}
                />}
            /> :
            < TextField
                label={labelCity}
                fullWidth
                inputProps={{ maxLength: 100 }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
    );
};


export default ComuneSelect;
