
import { Autocomplete, Box, Button, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import MuiFormText from '../Shared/Widgets/MuiText/MuiFormText';
import { showErrorNotification } from '@/features/swal/slice';
import { getResidencyCountries } from '@/features/countries/slice';
import { ResidencyCountryCode } from '@/features/countries/types';
import { getUserDocumentTypesByCountry, updateDocumentUserInformation } from '@/features/user/userSlice';
import { DocumentInformationPayload, UserDocumentTypes } from '@/features/user/types';
import { normalizeString } from '@/helpers/normalizeString';
import { useTranslation } from 'react-i18next';
import GenderSelect from './components/GenderSelect';
import ComuneSelect from './components/ComuneSelect';
import { useDispatch, useSelector } from 'react-redux';
import { isItalianOnboarding } from '@/helpers/kyc';
import { RootState } from '@/rootReducer';
import { KycAddressPayload, KycAddressTypeEnum } from '@/features/kyc/types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import MuiTextField from '../Shared/FormikComponents/MuiTextField';
import CountryCodesAlpha3 from '../Shared/FormikComponents/CountryCodesAlpha3';
import { isEmpty } from 'lodash';
import { useComune } from '@/helpers/customHook/useValidations';
import { createKycAddress, getKycAddressList } from '@/features/kyc/slice';
import CountryStates from '../Shared/FormikComponents/CountryStates';
import { getStateList } from '@/features/countryStates/countryStateSlice';

interface Props {
    onComplete: () => void,
}


const IdentitySumsub = ({ onComplete }: Props) => {

    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const [countryList, setCountryList] = useState<ResidencyCountryCode[]>([]);
    const [documents, setDocuments] = useState<UserDocumentTypes>();
    const allCountriesList: any = getNames('en');

    const [loading, setLoading] = useState(false);

    const [activeCountry, setActiveCountry] = useState<string>('');
    const [activeDocument, setActiveDocument] = useState<{ code: string, label: string, logo: string } | any>('');
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const { status } = useSelector((state: RootState) => state.user);
    const kycAddressList = useSelector((state: RootState) => state.kyc.kycAddressList);
    const kycResidence = kycAddressList.find(p => p.addressType === KycAddressTypeEnum.RESIDENCE_ADDRESS);
    const comunes = useSelector((state: RootState) => state.italyTerritory.comunes);
    const defaultComune = comunes.find(p => p.codiceCatastale === kycResidence?.loc_municipality);
    const [activePlace, setActivePlace] = useState<string>(defaultComune?.codiceCatastale || '');
    const { stateCountries } = useSelector((state: RootState) => state.countryState);

    const isItaOnboarding = isItalianOnboarding(status);
    const disabled = !activeDocument?.code || loading;


    const countryOptions = countryList.map(c => ({
        code: c.countryCode,
        label: allCountriesList[alpha3ToAlpha2(c.countryCode)]
    })) || [];

    const documentOptions = documents?.docTypes?.map(c => ({
        code: c.type,
        label: t('kyc.documentType.' + c.type, normalizeString(c.type)),
        logo: c.logo
    })) || [];


    useEffect(() => {
        const get = async () => {
            try {
                const result = await getResidencyCountries();
                setCountryList(result);
            }
            catch (e) {
                showErrorNotification(e);
            }
        };
        get();
    }, []);

    useEffect(() => {
        const get = async () => {
            try {
                if (activeCountry) {
                    setLoadingDocuments(true);
                    const result = await getUserDocumentTypesByCountry(activeCountry);
                    setDocuments(result);
                }
            }
            catch (e) {
                setDocuments(undefined);
            }
            finally {
                setLoadingDocuments(false);
            }
        };
        get();
    }, [activeCountry]);

        useEffect(() => { 
            dispatch(getStateList());
        }, [dispatch]);

    const submitKycAddress = async (authority: string, countryIso3: string, place: string, state: string) => {


        const provice = comunes.find(p => p.codiceCatastale === place)?.siglaAutomobilistica || undefined;
        const payload: KycAddressPayload = {
            addressType: KycAddressTypeEnum.IDENTITY_DOCUMENT_ISSUING_AUTH,
            country: countryIso3,
            city: countryIso3 === 'ITA' ? undefined : place,
            municipality: countryIso3 === 'ITA' ? place : undefined,
            province: provice,
            authority: authority,
            state,
        };
        await createKycAddress(payload);
        await dispatch(getKycAddressList());

    };

    const handelSubmit = async (formData) => {
        try {
            setLoading(true);
            const payload: DocumentInformationPayload = {
                preferredDocType: activeDocument?.code,
                country: formData.country,
                userProvidedGender: isItaOnboarding ? formData?.gender : undefined,
            };

            await updateDocumentUserInformation(payload);
            if (isItaOnboarding) { await submitKycAddress(formData?.documentAuthority, formData.country, formData?.comune, formData?.state); }
            setLoading(false);
            onComplete();
        }
        catch (e) {
            setLoading(false);
            showErrorNotification(e);
        }

    };

    const handleCountryChange = (newValue: any, setFieldValue, setFieldTouched) => {
        setActiveCountry(newValue);
        setActiveDocument('');
        setActivePlace('');
        setFieldValue('comune', '');
        setFieldValue('state', '');
        setTimeout(() => setFieldTouched('comune', true));
        setTimeout(() => setFieldTouched('state', true));
    };


    const handleDocumentChange = (e: any, newValue: any) => {
        setActiveDocument(newValue);
    };

    const handleSetComune = (newValue: any, setFieldValue: any) => {
        setFieldValue('comune', newValue);
        setActivePlace(newValue);
    };

    const handleSetGender = (newValue: any, setFieldValue: any) => {
        setFieldValue('gender', newValue);
    };


    const comuneValidation = useComune();
    const validationSchema = Yup.object({
        country: Yup.string().required(t('form.validator.required')),
        state: Yup.string().when(['country',], {
            is: (country) => stateCountries.includes(country),
            then: Yup.string().required(t('form.validator.required')),
            otherwise: Yup.string().notRequired()
        }),
        ...(isItaOnboarding
            ? {
                gender: Yup.string()
                    .required(t('form.validator.required')),
                comune: comuneValidation,
                documentAuthority: Yup.string().trim()
                    .label(t('landing.profileDetailsOam.documentAuthority'))
                    .min(2)
                    .max(100)
                    .matches(/^([a-zA-Z0-9-'.,-_`\s])+$/, t('form.validator.latinOnly'))
                    .required(t('form.validator.required')),
            } : {}
        ),
    });

    const initialValues = {
        country: '',
        gender: '',
        comune: '',
        documentAuthority: '',
        state: '',
    };

    return (
        <Grid>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handelSubmit}
            >{({ values, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
                const stateRequired = stateCountries.includes(values?.country);
                return (
                    <Form style={{ width: '100%' }}>
                        <Grid container >
                            <Grid item xs={12}>
                                <MuiFormText mb='30px'>{t('landing.identityStep.details')}</MuiFormText>
                            </Grid>
                            <Grid item xs={12}>
                                <CountryCodesAlpha3
                                    fieldName='country'
                                    fieldlabel={t('form.fields.documentCountry')}
                                    countryList={countryOptions}
                                    onChange={(v) => handleCountryChange(v, setFieldValue, setFieldTouched)} />
                            </Grid >
                            {stateRequired && <Grid item xs={12}>
                                <CountryStates
                                    fieldName='state'
                                    countryIso3={values.country} />

                            </Grid>
                            }
                            <Grid item xs={12}>
                                {documentOptions?.length > 0 &&
                                    <Autocomplete
                                        options={documentOptions}
                                        value={activeDocument}
                                        onChange={handleDocumentChange}
                                        disableClearable
                                        fullWidth
                                        sx={{ width: '100%', display: 'flex', alignContent: 'center' }}
                                        isOptionEqualToValue={(option, value) => value === undefined || value === '' ||
                                            (typeof option === 'object' && typeof value === 'object' && option?.code === value?.code)}
                                        renderOption={(props, option) => (
                                            typeof option === 'object' ?
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={option.logo}
                                                        alt=""
                                                    />
                                                    {option.label}
                                                </Box> :
                                                <></>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                name="preferredDocType"
                                                label={t('form.fields.identityDocType')}
                                                {...params}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off',
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (activeDocument?.logo ?
                                                        <InputAdornment position="start" sx={{ height: '100%' }}>
                                                            <img
                                                                loading="lazy"
                                                                width='20'
                                                                src={activeDocument.logo}
                                                                alt=""
                                                            />
                                                        </InputAdornment> : <></>
                                                    ),
                                                }}
                                            />)} />
                                }
                                {
                                    documentOptions?.length === 0 && values?.country && !loadingDocuments &&
                                    <MuiFormText mt='30px'>{t('kyc.documentType.notSupported')}</MuiFormText>
                                }
                            </Grid>
                            {isItaOnboarding &&
                                <>
                                    <Grid item xs={12}>
                                        <GenderSelect value={values.gender} onChange={(value: string) => handleSetGender(value, setFieldValue)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ComuneSelect
                                            labelComune={t('landing.profileDetailsOam.documentComune')}
                                            labelCity={t('landing.profileDetailsOam.documentCity')}
                                            countryIso3={values?.country}
                                            value={activePlace}
                                            onChange={(value) => handleSetComune(value, setFieldValue)} />
                                        {errors?.comune && <FormHelperText > {errors.comune} </FormHelperText>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiTextField
                                            name='documentAuthority'
                                            label={t('landing.profileDetailsOam.documentAuthority')}
                                            fullWidth
                                            inputProps={{ maxLength: 100 }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} mt={'50px'} >
                                <Button
                                    variant='contained'
                                    type="submit"
                                    fullWidth
                                    disabled={disabled || isSubmitting || !isEmpty(errors)}
                                >
                                    {t('form.buttons.next')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>);
            }}
            </Formik>
        </Grid>);
};

export default IdentitySumsub;
