import * as React from 'react';
import '@shared/SharedFormLayout.css';

import { Box, Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { postFile } from '@features/files/slice';
import { runOperation } from '@features/operations/slice';
import { showException, showSuccess } from '@features/swal/slice';
import { useCloseDialogNoEvent } from '@helpers/customHook/useCloseDialog';
import { getAccountName } from '@helpers/getAccountName';
import { getCurrencySign } from '@features/fees/slice';
import FormHeader from '@/componentsMui/Wallet/Balances/Deposit/Components/FormHeader';
import { PaymentPayloadType, PaymentTargetType } from '@/componentsMui/Type/PaymentPayloadType';
import { AccountIntegration } from '@features/account/types';
import { SystemType } from '@helpers/globalTypes';
import StyledCheckbox from '@shared/Widgets/StyledCheckbox';
import { isEmpty } from 'lodash';
import MuiFormText from '@shared/Widgets/MuiText/MuiFormText';
import { AccountType } from '@features/operations/types';
import { useState } from 'react';
import { Contact } from '@features/contacts/types';
import TokenAvatar, { TokenAvatarSizeEnum } from '@shared/Widgets/Avatars/TokenAvatar';
import ConfirmationLine from '@shared/Components/SendMoney/PaymentForm/ConfirmationLine';
import { getNames } from 'i18n-iso-countries';
import useFetchWithDelay from '@/helpers/customHook/useFetchWithDelay';
import { getAccounts } from '@/features/account/slice';

export interface Props {
	paymentPayload: PaymentPayloadType,
	prevStep: () => void,
	integration?: AccountIntegration,
	paymentName: string,
}

const NewPaymentConfirmation = ({ paymentPayload, prevStep, integration, paymentName }: Props) => {

	const { t } = useTranslation('translations');
	const closeModal = useCloseDialogNoEvent();
	const [submitting, setSubmitting] = React.useState(false);
	const { fetchWithDelay } = useFetchWithDelay();

	const [saveToContacts, setSaveToContacts] = useState<boolean>(paymentPayload.addToContacts ?? null);
	const [contact] = useState<Contact>(paymentPayload.contact ?? null);

	const showContact = paymentPayload.account?.type !== AccountType.TRUST;

	const curr = getCurrencySign(paymentPayload.account.currency ?? '');
	const countries = getNames('en');

	const send = async () => {
		setSubmitting(true);
		try {
			const attachments: Map<string, string> = new Map<string, string>();
			if (paymentPayload.selectedFile) {
				const formData = new FormData();
				formData.append('file', paymentPayload.selectedFile);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, paymentPayload.selectedFile.name);
			}

			const payload = {
				ccy: paymentPayload.account.currency,
				amount: paymentPayload.amount,
				details: paymentPayload.reference,
				accountId: paymentPayload.account.accountId,
				beneficiaryName: paymentPayload.to,
				isToBusiness: paymentPayload.isToBusiness,
				saveToContacts: saveToContacts,
				attachments: Object.fromEntries(attachments),
				...(integration === AccountIntegration.PRIMETRUST ? {
					beneficiaryAccountNumber: paymentPayload.accountNumber,
					beneficiaryEmail: paymentPayload.to,
					...(paymentPayload.paymentType === SystemType.US_WIRE &&
					{
						beneficiaryAddressCountry: paymentPayload.beneficiaryData.country,
						beneficiaryAddressCity: paymentPayload.beneficiaryData.city,
						beneficiaryAddressStreet: paymentPayload.beneficiaryData.street,
						beneficiaryRoutingNumber: paymentPayload.beneficiaryData.routingNumber,
						beneficiaryPostalCode: paymentPayload.beneficiaryData?.postalCode,
						beneficiaryRegion: paymentPayload.beneficiaryData?.region,
					}
					),
					...(paymentPayload.paymentType === SystemType.INTERNATIONAL_WIRE &&
					{
						beneficiaryAddressCountry: paymentPayload.beneficiaryData.country,
						beneficiaryAddressCity: paymentPayload.beneficiaryData.city,
						beneficiaryAddressStreet: paymentPayload.beneficiaryData.street,
						beneficiaryBankName: paymentPayload.beneficiaryData.bank,
						beneficiaryBicSwift: paymentPayload.beneficiaryData.bic,
					}
					),
					...((paymentPayload.paymentType === SystemType.ACH || paymentPayload.paymentType === SystemType.SAME_DAY_ACH) &&
					{
						isToBusiness: paymentPayload.isToBusiness,
						beneficiaryAccountType: paymentPayload.beneficiaryData.accountType,
						beneficiaryRoutingNumber: paymentPayload.beneficiaryData.routingNumber,
					}
					),
				} : {
						beneficiaryIdentityNo: paymentPayload.identification,
						...(paymentPayload.paymentTarget === PaymentTargetType.IBAN ?
							{
								beneficiaryIban: paymentPayload.iban,
								beneficiaryBicSwift: paymentPayload.beneficiaryData.bic,
								beneficiaryBankCountry: paymentPayload.beneficiaryData.country,
								beneficiaryBankName: paymentPayload.beneficiaryData.bank,
								beneficiaryBankAddress: paymentPayload.beneficiaryData.address
							} :
							{
								beneficiarySortCode: paymentPayload.sortCode,
								beneficiaryAccountNumber: paymentPayload.accountNumber
							}
						),
					}
				)

			};

			await runOperation(paymentPayload.proc, payload);
			fetchWithDelay(getAccounts(), [3000, 5000, 10000]);
			showSuccess({
				title: 'paymentsubmitted.data.header', text: t('paymentsubmitted.data.sentamount',
					{
						amount: curr + '' + paymentPayload?.amount, account: paymentPayload?.to,
						interpolation: { escapeValue: false }
					},
				), mui: true
			});

		} catch (e) {
			showException(e);
		}
		finally { closeModal(); setSubmitting(false); }

	};

	const handleConfirm = () => {
		send();
	};

	return (
		<div className='form-content__wrap__bb'>
			<Grid container >
				<Grid item xs={12} >
					<FormHeader title={t('sendmoney.data.confirmheader')} onBack={prevStep} showBack={!!prevStep} />
				</Grid>
				<Grid container item xs={12} alignItems="center" flexWrap='nowrap'>
					<TokenAvatar symbol={paymentPayload.account.currency} logoSource={paymentPayload.account.currencyLogo ? paymentPayload.account.currencyLogo : 'https://abcmob-public.s3.eu-central-1.amazonaws.com/currency_logo/GBP.png'} variant={TokenAvatarSizeEnum.normal} />
					<Typography ml={2} color='text.secondary' sx={{ fontSize: '1.225rem' }}>
						{paymentPayload.account.currency} - {paymentName ?? t('banking.addMoney.transfer')}
					</Typography>
				</Grid>
				<Grid container item xs={12} mt={1}>
					<Box sx={(theme) => ({
						background: theme.backgroundBoxLevel1,
						borderRadius: theme.mainBorderRadius,
						p: 2,
						width: '100%',
						mb: { xs: 3, md: 0 },
						mt: 1
					})}>
						<Grid container item rowSpacing={1}>
							{paymentPayload?.account && <Grid item xs={6}>
								<ConfirmationLine label={t('form.fields.from')}>
									{`${getAccountName(t, paymentPayload.account)} `}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.to && <Grid item xs={6}>
								<ConfirmationLine label={t('sendmoney.data.beneficiary')}>
									{paymentPayload.to}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.iban && <Grid item xs={12} sm={6}>
								<ConfirmationLine label={t('wallet.beneficiary.iban')}>
									{paymentPayload.iban}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.beneficiaryData?.bic && <Grid item xs={6}>
								<ConfirmationLine label={t('wallet.beneficiary.bicCode')}>
									{paymentPayload.beneficiaryData.bic}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.beneficiaryData?.country && <Grid item xs={6}>
								<ConfirmationLine label={t('wallet.beneficiary.bankCountry')}>
									{countries[paymentPayload.beneficiaryData.country]}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.reference && <Grid item xs={6}>
								<ConfirmationLine label={t('banking.data.reference')}>
									{paymentPayload.reference}
								</ConfirmationLine>
							</Grid>}

							{paymentPayload?.amount && <Grid item xs={6}>
								<ConfirmationLine label={t('sendmoney.data.transferamount')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={paymentPayload.amount}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid>}
							{paymentPayload?.fee ? <Grid item xs={6}>
								<ConfirmationLine label={t('sendmoney.data.transferfee')}>
									<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={paymentPayload.fee}
										prefix={`${curr} `} />
								</ConfirmationLine>
							</Grid> : null}
							<Grid item xs={12}>
								<ConfirmationLine label={t('sendmoney.data.totalAmount')}>
									<Typography
										fontSize='1.225rem'
										fontWeight='800'
										lineHeight='1.288rem'
										variant='highlight'
										style={{ display: 'inline' }}
									>
										<NumberFormat
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											value={paymentPayload.youpay}
											prefix={`${curr} `} />
									</Typography>
								</ConfirmationLine>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{(showContact && isEmpty(contact)) &&
					<Grid item xs={12} mt={2}>
						<FormControlLabel
							control={<StyledCheckbox
								checked={saveToContacts}
								onChange={() => { setSaveToContacts(!saveToContacts); }}
								disabled={!isEmpty(contact)} />}
							label={<MuiFormText>{t('sendmoneyTranslation.data.addtocontacts')}</MuiFormText>} />
						{/*{!isEmpty(contact) && <Typography variant='body2'>{t('sendmoneyTranslation.data.alreadyInContacts')}</Typography>}*/}
					</Grid>
				}
			</Grid>
			<Grid container mt='auto' pt='2rem' columnSpacing={2}>
				<Grid item xs={6}>
					<Button
						variant='customOutlined'
						color='secondary'
						onClick={prevStep}
						sx={{ width: { xs: '100%', md: 'auto' } }}
					>  {t('form.buttons.back')}
					</Button>
				</Grid>
				<Grid item xs={6} container justifyContent='flex-end'>
					<Button
						variant="contained"
						color="primary"
						onClick={handleConfirm}
						sx={{ width: { xs: '100%', md: 'auto' } }}
						disabled={submitting}
					>
						{t('createaccounttab.btn')}
					</Button>
				</Grid>
			</Grid>
		</div>);
};

export default NewPaymentConfirmation;
