import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { CountryStateLookupType, FndValueType } from './countryStateTypes';
import _ from 'lodash';
import { alpha2ToAlpha3 } from 'i18n-iso-countries';

const initialState: CountryStateLookupType = {
    list: [],
    stateCountries: [],
};

const countryStateSlice = createSlice({
    name: 'countryStateSlice',
    initialState,
    reducers: {
        setCountryState(state, action: PayloadAction<FndValueType[]>) {
            const transformedList = action.payload?.map(p => ({
                countryIso2: p.attribute1,
                countryIso3: alpha2ToAlpha3(p.attribute1) || '',
                stateCode: p.lookupCode,
                enabled: p.enabled,
                description: p.description,
            })) || []; 
            state.list = _.sortBy(transformedList, 'countryIso2', 'description');
            state.stateCountries = _.uniq(_.map(transformedList, 'countryIso3')) || [];
        },

    },
});

export const { setCountryState } = countryStateSlice.actions;

export const getStateList = () => {
    return async (dispatch) => {
        try { 
            const response = await request.get('/api/fnd/states');
            dispatch(setCountryState(response?.data || []));
        }
        catch (e) {
            dispatch(setCountryState([]));

        }
    };
};

export default countryStateSlice.reducer;
