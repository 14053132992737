
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ProductCard, { ButtonDetails } from '@/componentsMui/Shared/Widgets/ProductCard/ProductCard';
import { BankAccount } from '@/features/account/types';
import { useDispatch } from 'react-redux';
import { setAccount } from '@/features/tabs/slice';
import './TrustCard.css';
import { Box } from '@mui/material';
import TrustCardContent from './TrustCardContent';

interface Props {
	account: BankAccount,
	hideValues: boolean
}

const TrustCard = ({ account, hideValues }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();

	const handleAccess = () => {
		dispatch(setAccount(account));
		history.push(`/wallet/home/trust/account?id=${account.accountId}`);
	};

	const sendMoney: ButtonDetails = {
		title: t('trusts.access'),
		disabled: false,
		buttonHandler: handleAccess
	};
	return (
		<Box height='200px' sx={{width: {xs: '100%', sm: '376px'}}}>
			<ProductCard
				sizesClass='account'
				title={<TrustCardContent account={account} hideValues={hideValues} />}
				actionButtons={[sendMoney]}
			/>
		</Box>
	);
};

export default TrustCard;

