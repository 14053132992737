import * as React from 'react';
import { Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiTextField from './MuiTextField';
import { useField } from 'formik';
import StyledPaper from '@/componentsMui/Shared/Widgets/StyledPaper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getStateList } from '@/features/countryStates/countryStateSlice';

interface Props {
	countryIso3: string,
	fieldName: string,
	fieldlabel?: string,
	onDropdownChange?: (value: any) => void,
}


const CountryStates = ({ countryIso3, fieldName, fieldlabel, onDropdownChange, ...otherProps }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(fieldName);
	const { setValue } = helpers;
	const { value } = meta;
	const { list } = useSelector((state: RootState) => state.countryState);
	const dispatch = useDispatch();


	const { t } = useTranslation(['translations']);
	const options = list?.filter(p => p.enabled && p.countryIso3 == countryIso3)?.map(p => { return ({ label: p.description, code: p.stateCode }); }) || [];

	const activeOption = options.find(p => p.code === value);

	React.useEffect(() => {
		if (list.length === 0) {
			dispatch(getStateList());
		}
	}, [list.length, dispatch]);


	const handleCountryChange = (event: any, value: any) => {
		const newVal = typeof value === 'object' ? (value?.code ?? '') : (value ?? '');
		setValue(newVal);
		onDropdownChange && onDropdownChange(newVal);
	};

	const configTextfield = {
		...field,
		...otherProps,
		error: false,
		helperText: null
	};


	if (meta && meta.touched && meta.error) {
		configTextfield.error = true;
		configTextfield.helperText = meta.error;
	}

	return (
		<Autocomplete
			id='country-states'
			options={options}
			value={activeOption || null}
			onChange={handleCountryChange}
			disableClearable
			fullWidth
			sx={{ width: '100%' }}
			isOptionEqualToValue={(option, value) => value === undefined ||
				(typeof option === 'object' && typeof value === 'object' && option?.code === value?.code)}
			PaperComponent={({ children }) => (<StyledPaper >{children}</StyledPaper>)}
			renderInput={(params) => <MuiTextField fullWidth {...configTextfield} name={fieldName} {...params}
				label={fieldlabel ?? t('form.fields.state')}
				inputProps={{
					...params.inputProps,
					autoComplete: 'new-password', // disable autocomplete and autofill
				}}
			/>}
		/>);
};


export default CountryStates;
