export interface KYC {
	token: string,
	error: string,
	loading: boolean,
	kycAddressList: KycAddress[],
	kycAddressLoading: boolean,
}

export interface ProfilePayload {
	country: string,
	street: string,
	town: string,
	postalCode: string,
	firstName?: string,
	lastName?: string,
	state?: string,
	taxCountry?: string,
	taxState?: string,
	taxIdNumber?: string,
}


export interface KycAddress {
	id?: number,
	addressType: string,
	isActive?: boolean,
	activeFrom?: Date,
	activeTo?: Date,
	languageCode?: string,
	loc_municipality?: string,
	loc_province?: string,
	loc_country?: string,
	loc_state?: string,
	city?: string,
}

export interface KycAddressPayload {
	addressType: string,
	country: string,
	municipality?: string,
	province?: string,
	city?: string,
	authority?: string,
	state?: string,
}

export enum KycAddressTypeEnum {
	PLACE_OF_BIRTH = 'PLACE_OF_BIRTH',
	RESIDENCE_ADDRESS = 'RESIDENCE_ADDRESS',
	IDENTITY_DOCUMENT_ISSUING_AUTH = 'IDENTITY_DOCUMENT_ISSUING_AUTH'
}
